/* You can add global styles to this file, and also import other style files */
/* @import "@angular/material/prebuilt-themes/indigo-pink.css"; */

/* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
/* @import '~css-star-rating/scss/star-rating'; */


/* @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400&display=swap'); */


.xboder-abajo {
    border-bottom: 1px solid #c7c7c7;
}

.xboder-derecha {
    border-right: 1px solid #c7c7c7;
}

.xcontainer-center {
    display: flex;    
    align-items: center;
}

.xcontainer-centrado {
    display: grid;    
    align-items: center;
    height: 100vh;
}

.xfooter {
    width: 100%;
    bottom: 0px;
    position: fixed;
    background: #e0e0e0;
    /* height: 48px; */
}

/* .skiptranslate {
    display: none;
} */


.content-install {
    display: none;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    background: royalblue;
    padding: 50px;
    height: 100vh;
    z-index: 2;
    opacity: 0.9;
}

.content-install .div-item {
    top: 40%;
    width: 100%;
    position: relative;
    color: white;
}

.btn-install {
    padding: 10px;
    background: aquamarine;
    color: black;
    width: 200p;
    border-radius: 2px;
}

.content-resumen-pedido {
    max-width: 500px;
    margin: 0 auto;
    border: 1px solid #aaa;
    border-top: none;
}
